import { defineComponent } from 'vue';
export default defineComponent({
    data: function () {
        return {
            imgs: null
        };
    },
    mounted: function () {
        var imgs = new Array();
        for (var i = 1; i <= 32; i++) {
            imgs.push({ src: '/img/' + i + '.jpeg', loading: null });
        }
        this.imgs = imgs;
    },
    methods: {},
    components: {}
});
