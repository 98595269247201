import {createApp} from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import VueLazyLoad from 'vue3-lazyload';

let app = createApp(App);
app.use(router);
app.use(VueLazyLoad);
app.mount('#app');
