import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "slide" }

export function render(_ctx, _cache) {
  const _directive_lazy = _resolveDirective("lazy")

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imgs, (img) => {
    return _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_1, null, 512)), [
      [_directive_lazy, img]
    ])
  }), 256))
}