import {createRouter, createWebHistory, RouterOptions} from 'vue-router'

const routes: Array<any> = []

const router = createRouter({
  history: createWebHistory(),
  routes
} as RouterOptions);

export default router;

